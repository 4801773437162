import React from "react"
import { Link } from "gatsby"
import info from '../config/config'
import Layout from "../components/layout"

import Image from "../components/image"
import BgSection from "../components/bgimage"

import Button from "../components/button"
import Logo from "../components/icons/sakaya_mv_logo"
import Widgets from "../components/widgets"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout type="sakaya">
    <SEO title="酒屋元木" description="酒屋元木では酒場元木でお出ししているお酒がお買い求め頂けます。贈答用のラッピングや熨斗、地方発送も承っております。" image="/images/OGP_sakaya_20200815.jpg" />
    <div id="content">
      <BgSection id="mv" className="cSection" filename="sakaya_kv@2x.jpg">
        <p className="mvLogo"><Logo></Logo></p>
      </BgSection>
      <section id="about" className="cSection">
        <div className="cTitleArea">
          <div className="cInnerArea">
            <h3 className="sakayaCatch">酒場元木でお出ししているお酒が<br className="cPC" />
            お買い求め頂けます。</h3>
            <div className="textArea">
              <p className="text1">酒屋元木は2019年に酒販免許を取得いたしました。<br />
              “酒場”元木で日頃お飲み頂いている美味しいお酒を、お持ち帰り可能な酒販業務を行っております。<br />
              定番商品である季の美（限定ものを含めた各種）、アラン10年、キルホーマン、フィーバーツリーのトニックやジンジャービアが1本からお求め頂けます。</p>
              <p className="btn1"><Link to="/">
                <Button>酒場元木ページ</Button>
              </Link></p>
            </div>
          </div>
        </div>
        <div className="cItemList">
        <h3 className="dBoxTitle">酒屋元木のお取り扱い商品例</h3>
        <ul>
           <li>
                <Image filename="sakaya/item11@2x.jpg" alt="" />
								<dl>
									<dt className="sakayaListTitle">季の美<br/>京都 ドライジン</dt>
									<dd className="cat">GIN / 45％・700ml</dd>
								</dl>
						</li>
            <li>
								<Image filename="sakaya/item1@2x.jpg" alt="" />
								<dl>
									<dt className="sakayaListTitle">季のTEA（茶）</dt>
									<dd className="cat">GIN / 45％・700ml</dd>
								</dl>
						</li>
            <li>
								<Image filename="sakaya/item2@2x.jpg" alt="" />
								<dl>
									<dt className="sakayaListTitle">季の勢（セイ）</dt>
									<dd className="cat">GIN / 54％・700ml</dd>
								</dl>
						</li>
            <li>
								<Image filename="sakaya/item3@2x.jpg" alt="" />
								<dl>
									<dt className="sakayaListTitle">季の美・TEA・勢<br/>飲み比べ3本セット</dt>
									<dd className="cat">GIN / 200ml×３本</dd>
								</dl>
						</li>
            <li>
								<Image filename="sakaya/item4@2x.jpg" alt="" />
								<dl>
									<dt className="sakayaListTitle">季の美 ドライジン<br/>一升瓶</dt>
									<dd className="cat">GIN / 45％・1800ml</dd>
								</dl>
						</li>
            <li>
								<Image filename="sakaya/item6@2x.jpg" alt="" />
								<dl>
									<dt className="sakayaListTitle">アラン１０年</dt>
									<dd className="cat">WHISKY / 46％・700ml</dd>
								</dl>
						</li>
            <li>
								<Image filename="sakaya/item7@2x.jpg" alt="" />
								<dl>
									<dt className="sakayaListTitle"><span>キルホーマン</span><br/>マキヤーベイ</dt>
									<dd className="cat">WHISKY / 46％・700ml</dd>
								</dl>
						</li>
            <li>
								<Image filename="sakaya/item9@2x.jpg" alt="" />
								<dl>
									<dt className="sakayaListTitle"><span>フィーバーツリー</span><br/>トニックウォーター</dt>
									<dd className="cat">MIKISER / 200ml</dd>
								</dl>
						</li>
            <li>
								<Image filename="sakaya/item10@2x.jpg" alt="" />
                <dl>
									<dt className="sakayaListTitle"><span>フィーバーツリー</span><br/>ジンジャービア</dt>
									<dd className="cat">MIKISER / 200ml</dd>
								</dl>
						</li>
        </ul>
        <div className="cInnerArea">
          <p className="note">
          ※限定ボトルなどの入荷情報は<a className="cAlpha" href={info.sakabaFB} target="_blank" rel="noreferrer">酒場元木Facebook</a>にてお知らせしております
          </p>
          </div>
        </div>
      </section>
      <section id="contact" className="cSection">
        <div className="cTitleArea">
          <div className="cInnerArea">
            <div className="box">
              <h2 className="cTitle">
                  <span className="ja">お問い合わせ</span>
                  <span className="en">Contact</span>
                </h2>
                <p className="text1">酒屋元木では、贈答用のラッピングや熨斗、地方発送も承っております。<br/>
                ご近所の料飲店様へは配達も行っており、ロットに応じて割引きもございます。<br/>
                ぜひお問い合わせ下さい。
                </p>
                <dl className="dBoxTitle">
                <dd><a className="cAlpha" href={`tel:${info.tel}`}><span>{info.tel}</span></a></dd>
                <dd><a className="cAlpha" href={`mailto:${info.mail}`}><span>{info.mail}</span></a></dd>
              </dl>
            </div>
            <figure>
              <Image filename="sakaya/contact_img1@2x.jpg" alt="" />
              <Image filename="sakaya/contact_img2@2x.jpg" alt="" />
              <Image filename="sakaya/contact_img3@2x.jpg" alt="" />
            </figure>
          </div>
        </div>
      </section>
      <Widgets type="sakaya" />
    </div>
    {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Ojisan />
    </div>
    <Link to="/page-2/">Go to page 2</Link> <br />
    <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
  </Layout>
)

export default IndexPage
